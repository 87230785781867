@charset "utf-8";
.edd-root,
.edd-root *,
.edd-root *::before,
.edd-root *::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.edd-root {
    display: inline-block;
    position: relative;
    width: 518px;
    user-select: none;
    /*font-family: 'Roboto', arial, helvetica, sans-serif;
    font-weight: 300;*/
    font-size: 1.4rem;
    font-weight: normal;
    vertical-align: top;
}

.edd-root-disabled {
    color: #ccc;
    cursor: not-allowed;
}

.edd-head {
    position: relative;
    overflow: hidden;
    background: #FFF;
}

.edd-value {
    width: 100%;
    display: inline-block;
    vertical-align: middle;
    padding: 14px 22px;
    color: #595757;
    border: 1px solid #c6c6c6;
}
.edd-root-has-value .edd-value {
	color: #231815;
}

.edd-arrow {
    position: absolute;
    width: 10px;
    height: 6px;
    top: calc(50% - 3px);
    right: 17px;
    transition: transform 150ms;
    pointer-events: none;
    color: #666;
}

.edd-root-disabled .edd-arrow {
    color: #ccc;
}

.edd-arrow::before {
    content: '';
    position: absolute;
    border-top: 6px solid #6b696a;
    border-right: 5px solid transparent;
    border-left: 5px solid transparent;
    top: 0;
    right: 0;
}

.edd-root-open .edd-arrow {
    transform: rotate(180deg);
}

.edd-value,
.edd-option,
.edd-group-label {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.edd-root:not(.edd-root-disabled) .edd-value,
.edd-option {
    cursor: pointer;
}


.edd-select {
    position: absolute;
    opacity: 0;
    width: 100%;
    left: -100%;
    top: 0;
}

.edd-root-native .edd-select {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}

.edd-body {
    opacity: 0;
    position: absolute;
    left: 0;
    right: 0;
    pointer-events: none;
    overflow: hidden;
    z-index: 999;
    background: white;
    border: 1px solid #c6c6c6;
    border-top: 0;
    border-bottom: 0;
}

.edd-root-open .edd-body {
    opacity: 1;
    pointer-events: all;
    transform: scale(1);
    transition: opacity 200ms, transform 100ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.edd-root-open-above .edd-body {
    bottom: 100%;
}

.edd-root-open-below .edd-body {
    top: 100%;
}

.edd-items-list {
    /*overflow: auto;*/
    max-height: 0;
    transition: max-height 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
    -webkit-overflow-scrolling: touch;
}

.edd-items-list::-webkit-scrollbar {
    width: 12px;
}

.edd-items-list::-webkit-scrollbar-track {
    background: #efefef;
}

.edd-items-list::-webkit-scrollbar-thumb {
    background: #ccc;
}

.edd-group-label {
    font-size: 13px;
    padding: 4px 8px 4px 0;
    color: #555;
    font-weight: 600;
}

.edd-group-has-label {
    padding-left: 22px;
}

.edd-option {
    position: relative;
    padding: 11px 8px 13px 22px;
    border-bottom: 1px solid #c6c6c6;
}

.edd-option-selected {
    font-weight: bold;
}

/*.edd-option-selected::before {
    content: '';
    position: absolute;
    width: 8px;
    height: 4px;
    border-bottom: 2px solid #4ac5f1;
    border-left: 2px solid #4ac5f1;
    left: 6px;
    top: calc(50% - 4px);
    transform: rotate(-45deg);
}*/

.edd-option-focused:not(.edd-option-disabled) {
    color: #4ac5f1;
}

.edd-option-disabled,
.edd-group-disabled .edd-option {
    cursor: default;
    color: #ccc;
}

.edd-gradient-top,
.edd-gradient-bottom {
    content: '';
    position: absolute;
    left: 2px;
    right: 12px;
    height: 32px;
    background-image:
        linear-gradient(
            0deg,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 1) 40%,
            rgba(255, 255, 255, 1) 60%,
            rgba(255, 255, 255, 0) 100%
        );
    background-repeat: repeat-x;
    background-size: 100% 200%;
    pointer-events: none;
    transition: opacity 100ms;
    opacity: 0;
}

.edd-gradient-top {
    background-position: bottom;
    top: 0;
}

.edd-gradient-bottom {
    background-position: top;
    bottom: 0;
}

.edd-body-scrollable .edd-gradient-top,
.edd-body-scrollable .edd-gradient-bottom {
    opacity: 1;
}

.edd-body-scrollable.edd-body-at-top .edd-gradient-top,
.edd-body-scrollable.edd-body-at-bottom .edd-gradient-bottom {
    opacity: 0;
}
@media all and (max-width: 749px) {
	.edd-root {
		width: 100%;
	}
	.edd-value {
		padding: 10px 12px;
	}
}